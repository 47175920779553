import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { IoSearchOutline } from "react-icons/io5";
import { IoMdDownload } from "react-icons/io";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import axios from "axios";

export default function Agreements() {
  const [newContent, setNewContent] = useState(true);
  const [approvedContent, setApprovedContent] = useState(false);
  const [disapprovedContent, setDisapprovedContent] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [actionType, setActionType] = useState("");
  const [moreClick, setMoreClick] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showAnnexures, setShowAnnexures] = useState(false);
  const [sortDirection, setSortDirection] = useState(null);
  const [sortColumn, setSortColumn] = useState("dateOfAgreement");
  const [entries, setEntries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [misCount, setMisCount] = useState([]);

  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://artistaloud-backend.kollywoodhungama.com/v1/getAgreement"
        );
        const result = await response.json();

        if (result.message === "Agreement fetched successfully") {
          // Assuming the response data is in result.data
          setData(result.data);
        }
      } catch (err) {
        setError("Failed to fetch data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchMISCount = async () => {
      try {
        const response = await fetch(
          "https://artistaloud-backend.kollywoodhungama.com/v1/getMISCount"
        );

        if (response.status === 200) {
          const result = await response.json();

          if (Array.isArray(result.data) && result.data.length > 0) {
            const data = result.data[0];
            const totalCount =
              data.audioCount +
              data.videoCount +
              data.exclusiveCount +
              data.nonExclusiveCount;

            // Set the misCount state with totalCount added
            setMisCount({ ...data, totalCount });
            // setMisCount(result.data[0]); // Assuming the first object is required
          } else {
            console.error("Unexpected API response structure", result);
            setError("Unexpected API response structure");
          }
        } else {
          console.error("Failed to fetch data, status code:", response.status);
          setError("Failed to fetch data, status code: " + response.status);
        }
      } catch (err) {
        console.error("Error fetching MIS Count:", err);
        setError("Error fetching MIS Count");
      } finally {
        setLoading(false);
      }
    };

    fetchMISCount();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Formats to YYYY-MM-DD
  };

  // Helper function to check if a field is a valid URL
  // const isValidUrl = (str) => {
  //   try {
  //     new URL(str);
  //     return true;
  //   } catch (_) {
  //     return false;
  //   }
  // };

  const formattedData = data.map((item) => ({
    agreement: item.agreement_url,
    Name: item.Name,
    PAN: item.PAN,
    address: item.address,
    mobile: item.mobile,
    email: item.email,
    liveStatus: item.liveStatus,
    audio: item.audioVideo,
    video: item.audioVideo,
    // amazonLetters: item.amazonLetters,
    iprsMember: item.iprsMember,
    // spotifyLetters: item.spotifyLetters,
    distributionType: item.distributionType,
    // territory: item.territory,
    revenue: item.revenue,
    autoRenewal: item.autoRenewal,
    terms: item.terms,
    dateOfAgreement: item.dateOfAgreement,
    endDateOfAgreement: item.endDateOfAgreement,
    status: item.status,
    contentTitle: item.contentTitle,
  }));

  const handleDownloadCSV = () => {
    // Convert formattedData to CSV format
    const csvData = XLSX.utils.json_to_sheet(formattedData);
    const csvOutput = XLSX.utils.sheet_to_csv(csvData);

    // Create a Blob from the CSV output
    const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });

    // Use file-saver to trigger download
    saveAs(blob, "agreements.csv");
  };

  const handleDownloadExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert formattedData to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Agreements");

    // Generate the Excel file and trigger download
    XLSX.writeFile(workbook, "agreements.xlsx"); // Use .xlsx extension
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSort = (column) => {
    if (sortColumn === column && sortDirection === "asc") {
      setSortDirection("desc");
    } else {
      setSortDirection("asc");
    }
    setSortColumn(column);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleEntriesPerPageChange = (event) => {
    setEntriesPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(totalEntries / entriesPerPage);
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          className={`mx-1 ${i === currentPage ? "font-bold" : ""}`}
          onClick={() => setCurrentPage(i)}
        >
          {i < 10 ? `0${i}` : i}
        </button>
      );
    }
    return pages;
  };

  const totalEntries = data.length;
  // Step 1: Filter the data based on the search query
  const filteredData = data.filter((item) => {
    const matchesSearch =
      (item.Name &&
        item.Name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.PAN &&
        item.PAN.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.mobile &&
        item.mobile.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.status &&
        item.status.toLowerCase().includes(searchQuery.toLowerCase()));
    return matchesSearch;
  });

  // Step 2: Sort the filtered data by dateOfAccountCreation
  const filteredAndSortedData = filteredData.sort((a, b) => {
    const dateA = new Date(a[sortColumn]);
    const dateB = new Date(b[sortColumn]);

    if (sortDirection === "asc") {
      return dateA - dateB;
    } else if (sortDirection === "desc") {
      return dateB - dateA;
    }
    return 0;
  });

  // Step 3: Apply pagination to the filtered and sorted data
  const totalFilteredEntries = filteredAndSortedData.length; // Total entries after filtering and sorting
  const totalPages = Math.ceil(totalFilteredEntries / entriesPerPage); // Total number of pages after filtering

  const firstEntryIndex = (currentPage - 1) * entriesPerPage;
  const lastEntryIndex = Math.min(
    firstEntryIndex + entriesPerPage,
    totalFilteredEntries
  );

  // Slice the filtered and sorted data for pagination
  const paginatedData = filteredAndSortedData.slice(
    firstEntryIndex,
    lastEntryIndex
  );

  const renderTable = (data) => (
    <div className="scrollable-table ml-[5vw] w-[90vw] rounded-md">
      <table className="info w-full">
        <thead className="bg-[#252525] pb-4">
          <tr>
            <th
              className="mis-head rounded-l-md"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              No.
            </th>
            <th
              className="mis-head"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Download Agreement
            </th>
            <th
              className="mis-head min-w-[120px]"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Banner Name
            </th>
            {/* <th
              className="mis-head min-w-[120px]"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Name
            </th> */}
            <th
              className="mis-head min-w-[120px]"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              PAN No.
            </th>
            <th
              className="min-w-[200px]"
              style={{
                fontSize: "11px",
                fontWeight: "200",
                color: "white",
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Address
            </th>
            <th
              className="min-w-[120px]"
              style={{
                fontSize: "11px",
                fontWeight: "200",
                color: "white",
                position: "sticky",
                top: "0",
                background: "#252525  ",
                zIndex: "1",
              }}
            >
              Mobile No.
            </th>
            <th
              className="mis-head"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Email
            </th>
            <th
              className="mis-head"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Live Status
            </th>
            <th
              className="mis-head"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Audio
            </th>
            <th
              className="mis-head"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Video
            </th>
            {/* <th
              className="mis-head"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Amazon Letters
            </th> */}
            <th
              className="mis-head"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              IPRS Member
            </th>
            {/* <th
              className="mis-head"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Spotify Letters
            </th> */}
            <th
              className="mis-head"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Distribution Type
            </th>
            {/* <th
              className="mis-head"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Territory
            </th> */}
            <th
              className="mis-head"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Revenue
            </th>
            <th
              className="mis-head"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Auto Renewal
            </th>
            <th
              className="mis-head min-w-[100px]"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Term
            </th>
            <th
              className="mis-head min-w-[180px]"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Date of Agreement{" "}
              <button
                onClick={() => handleSort("dateOfAgreement")}
                className="ml-1"
              >
                {sortDirection === "asc" ? "🔼" : "🔽"}
              </button>
            </th>
            <th
              className="mis-head min-w-[200px]"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              End Date of Agreement{" "}
              <button onClick={() => handleSort("endDateOfAgreement")}>
                {sortColumn === "endDateOfAgreement" && sortDirection === "asc"
                  ? "🔼"
                  : "🔽"}
              </button>{" "}
            </th>
            <th
              className="mis-head"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Status
            </th>
            <th
              className="mis-head rounded-r-md min-w-[200px]"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Content Title
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="h-2 bg-transparent"></tr>
        </tbody>
        <tbody className="mt-[10%]">
          {filteredData.map((item, index) => (
            <React.Fragment key={index}>
              <tr key={item.no}>
                <td className="mis-data rounded-l-md">
                  {firstEntryIndex + index + 1}
                </td>
                <td className="mis-data">
                  <button
                    className={`rounded-3xl download-button ${
                      !item.agreement_url ? "disabled" : ""
                    }`}
                    style={{
                      backgroundColor: item.agreement_url ? "#161616" : "grey",
                      fontSize: "0.6rem",
                      padding: "8px",
                    }}
                    onClick={() => window.open(item.agreement_url || "#", "_blank")}
                    disabled={!item.agreement_url}
                  >
                    Download
                  </button>
                </td>
                <td className="mis-data">{item.Name || "-"}</td>
                <td className="mis-data">{item.PAN || "-"}</td>
                <td className="mis-data">{item.address || "-"}</td>
                <td className="mis-data">{item.mobile || "-"}</td>
                <td className="mis-data">{item.email || "-"}</td>
                <td className="mis-data">{item.liveStatus ? "YES" : "NO"}</td>
                <td className="mis-data">{item.audioVideo ? "YES" : "NO"}</td>
                <td className="mis-data">{item.audioVideo ? "YES" : "NO"}</td>
                {/* <td className="mis-data">{item.amazonLetters ? "YES" : "NO"}</td> */}
                <td className="mis-data">{item.iprsMember ? "YES" : "NO"}</td>
                {/* <td className="mis-data">{item.spotifyLetters ? "YES" : "NO"}</td> */}
                <td className="mis-data">{item.distributionType || "-"}</td>
                {/* <td className="mis-data">{item.territory || "-"}</td> */}
                <td className="mis-data">{item.revenue || "-"}</td>
                <td className="mis-data">{item.autoRenewal ? "Y" : "N"}</td>
                <td className="mis-data">{item.terms || "-"}</td>
                <td className="mis-data">{formatDate(item.dateOfAgreement)}</td>
                <td className="mis-data">
                  {formatDate(item.endDateOfAgreement)}
                </td>
                <td className="mis-data">{item.status || "-"}</td>
                <td className="mis-data rounded-r-md">
                  {item.contentTitle || "-"}
                </td>
              </tr>
              <tr className="gap-row">
                <td colSpan="2"></td>{" "}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
  return (
    <>
      <Header />
      <div className="flex ml-[5vw] mr-[5vw] mt-[5vh]">
        <div className="flex flex-col">
          <div className="flex items-center bg-[#1E1E1E] w-[90vw] h-[10vh] rounded-md">
            <div className="text-[#FF6C2F] text-[28px] ml-[5vw] font-medium">
              <Link to="/active-invoice">MIS</Link>
            </div>
            <div className="bg-[#FFFFFF] w-[1px] h-[25px] ml-[2vw]"></div>
            <Link
              to="/agreements"
              className="text-white text-[24px] ml-[2vw] font-medium"
            >
              Agreements
            </Link>
            <Link
              to="/new-users"
              className="text-[#5B5B5B] text-[24px] ml-[2vw] font-medium"
            >
              New Users
            </Link>
            <Link
              to="/upload-royalty-report"
              className="text-[#5B5B5B] text-[24px] ml-[2vw] font-medium"
            >
              Upload RR
            </Link>
          </div>
          <br />
          <div className="flex flex-col items-center justify-center bg-[#1E1E1E] w-[auto] h-[22vh] rounded-md text-white">
            <div className="flex items-center w-[100%] m-[auto]">
              <div className=" flex justify-center w-[15%]">
                <Link
                  to="/annexures"
                  className="px-6 py-2 bg-[#FEC961] rounded-md  text-black text-[0.8rem]"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Show Annexures
                </Link>
              </div>
              <div
                className=" w-[70%] flex items-center justify-center rounded-md text-[1rem] text-white"
                style={{ fontWeight: "bold" }}
              >
                <h2>Total Data</h2>
              </div>
            </div>
            <div className="flex bg-[#252525] w-[98%] h-[10vh] rounded-md mb-[1%]">
              <div className="flex items-center justify-around w-[100%]">
                <div className="flex w-[auto] h-[2.2rem] bg-[#161616] rounded-md">
                  <div className="flex items-center justify-center p-[10px]">
                    <h2 className="metasea-info">Audio Banners</h2>
                  </div>
                  <div
                    className="flex items-center justify-center p-[10px] bg-[black] rounded-md"
                    style={{ minWidth: "fit-content" }}
                  >
                    <h2 className="metasea-info">{misCount.audioCount}</h2>
                  </div>
                </div>
                <div className="flex w-[auto] h-[2.2rem] bg-[#161616] rounded-md">
                  <div className="flex items-center justify-center p-[10px]">
                    <h2 className="metasea-info">Video Banners</h2>
                  </div>
                  <div
                    className="flex items-center justify-center p-[10px] bg-[black] rounded-md"
                    style={{ minWidth: "fit-content" }}
                  >
                    <h2 className="metasea-info">{misCount.videoCount}</h2>
                  </div>
                </div>
                <div className="flex w-[auto] h-[2.2rem] bg-[#161616] rounded-md">
                  <div className="flex items-center justify-center p-[10px]">
                    <h2 className="metasea-info">Exclusive Banners</h2>
                  </div>
                  <div
                    className="flex items-center justify-center p-[10px] bg-[black] rounded-md"
                    style={{ minWidth: "fit-content" }}
                  >
                    <h2 className="metasea-info">{misCount.exclusiveCount}</h2>
                  </div>
                </div>
                <div className="flex w-[auto] h-[2.2rem] bg-[#161616] rounded-md">
                  <div className="flex items-center justify-center p-[10px]">
                    <h2 className="metasea-info">Non Exclusive Banners</h2>
                  </div>
                  <div
                    className="flex items-center justify-center p-[10px] bg-[black] rounded-md"
                    style={{ minWidth: "fit-content" }}
                  >
                    <h2 className="metasea-info">
                      {misCount.nonExclusiveCount}
                    </h2>
                  </div>
                </div>
                <div className="flex w-[auto] h-[2.2rem] bg-[#161616] rounded-md">
                  <div className="flex items-center justify-center bg-[#161616] p-[10px] rounded-md">
                    <h2 className="metasea-info">Total</h2>
                  </div>
                  <div
                    className="flex items-center justify-center p-[10px] bg-[black] rounded-md"
                    style={{ minWidth: "fit-content" }}
                  >
                    <h2 className="metasea-info">{misCount.totalCount}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="flex justify-between ml-[5vw] w-[90vw] h-[7vh] text-[0.8rem] text-white mb-[1.5%]">
        <div className=" flex w-[18%] ml-[0.5%] flex justify-between">
          <button
            className="flex justify-center items-center bg-[#161616] h-[7vh] rounded-md w-[7vw] border-[1px] border-[#FF6C2F]"
            onClick={handleDownloadExcel}
          >
            Excel{" "}
            <IoMdDownload
              style={{
                color: "white",
                fontSize: "1.4rem",
                padding: "4px",
              }}
            />
          </button>
          <button
            className="flex justify-center items-center bg-[#161616] h-[7vh] rounded-md w-[7vw] border-[1px] border-[#FF6C2F]"
            onClick={handleDownloadCSV}
          >
            CSV{" "}
            <IoMdDownload
              style={{
                color: "white",
                fontSize: "1.4rem",
                padding: "4px",
              }}
            />
          </button>
        </div>
        <div className="flex items-center text-[1.2rem] text-[#FF6C2F] font-semibold">
          <h2>MAIN AGREEMENT</h2>
        </div>
        <div className="w-[28%] ml-[0.5%] relative">
          <input
            type="search"
            name="searchBar"
            id=""
            className="bg-[#252525] text-white rounded-md outline-none border-[1px] border-[#FF6C2F] p-[10px] pl-[50px] w-full h-[7vh]"
            placeholder="Search.."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <div className="absolute top-[50%] left-[15px] transform -translate-y-[50%]">
            <IoSearchOutline className="text-white" />
          </div>
        </div>
      </div>
      <div className="mt-[2vh] mb-[2vh]">
        {renderTable(paginatedData)}{" "}
        <div className="flex justify-between items-center ml-[5vw] w-[90vw] h-[7vh] pl-[2%] pr-[2%] rounded-md bg-[#252525] text-white text-[0.7rem]">
          <div>
            {/* Showing {firstEntryIndex + 1} to {lastEntryIndex} of {totalEntries}{" "}
            entries */}
            Showing {firstEntryIndex + 1} of {totalEntries} entries
          </div>
          {/* <div className="flex items-center ">
            <span>Show </span>
            <select
              className="mx-2 p-1 border rounded bg-[#252525] outline-none text-white"
              value={entriesPerPage}
              onChange={handleEntriesPerPageChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <span>entries</span>
          </div> */}
          {/* <div className="flex items-center">
            <button
              className="mx-2"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className="mx-2"
              onClick={goToNextPage}
              disabled={
                currentPage === Math.ceil(totalEntries / entriesPerPage)
              }
            >
              Next
            </button>
          </div> */}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
