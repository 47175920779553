import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { IoSearchOutline } from "react-icons/io5";
import { IoMdDownload } from "react-icons/io";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
  FaEdit,
  FaCheck,
  FaEye,
  FaDownload,
} from "react-icons/fa";

export default function ActiveInvoices() {
  const [tableData, setTableData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  useEffect(() => {
    // Simulate fetching data from an API
    const fetchData = async () => {
      const dummyData = [
        {
          no: 1,
          vendor: "Vendor A",
          activeInvDate: "2024-03-01",
          activeInvNo: 101,
          payableAmount: 5000,
          gstAmount: 900,
          totalAmount: 5900,
          email: "vendorA@example.com",
          gstAvailable: "Yes",
          gstin: "22AAAAA0000A1Z5",
          pan: "AAAAA0000A",
          state: "Maharashtra",
          cin: "L12345MH2024PLC123456",
          sac: "9983",
          bankName: "XYZ Bank",
          branch: "Mumbai",
          accountType: "Current",
          accountNo: "1234567890",
          ifsc: "XYZB0001234",
          downloadActiveInv: "Download",
          cancelCheque: "Available",
          msme: "No",
          panImage: "PAN Image",
          gstImage: "GST Image",
        },
        {
          no: 2,
          vendor: "Vendor B",
          activeInvDate: "2024-03-02",
          activeInvNo: 102,
          payableAmount: 7000,
          gstAmount: 1260,
          totalAmount: 8260,
          email: "vendorB@example.com",
          gstAvailable: "No",
          gstin: "",
          pan: "BBBBB1111B",
          state: "Delhi",
          cin: "L12345DL2024PLC654321",
          sac: "9984",
          bankName: "ABC Bank",
          branch: "Delhi",
          accountType: "Savings",
          accountNo: "9876543210",
          ifsc: "ABCB0005678",
          downloadActiveInv: "Download",
          cancelCheque: "Available",
          msme: "Yes",
          panImage: "PAN Image",
          gstImage: "GST Image",
        },
      ];
      setTableData(dummyData);
    };
    fetchData();
  }, []);

  const handleDownloadCSV = () => {
    // Convert formattedData to CSV format
    const csvData = XLSX.utils.json_to_sheet(tableData);
    const csvOutput = XLSX.utils.sheet_to_csv(csvData);

    // Create a Blob from the CSV output
    const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });

    // Use file-saver to trigger download
    saveAs(blob, "active-invoices.csv");
  };

  const handleDownloadExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert formattedData to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(tableData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Active-Invoices");

    // Generate the Excel file and trigger download
    XLSX.writeFile(workbook, "active-invoices.xlsx"); // Use .xlsx extension
  };

  //   const handleSearchChange = (e) => {
  //     setSearchQuery(e.target.value);
  //   };

  const sortTable = (key, type) => {
    let sortedData = [...tableData];
    let direction = "asc";

    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    sortedData.sort((a, b) => {
      if (type === "number") {
        return direction === "asc" ? a[key] - b[key] : b[key] - a[key];
      } else {
        return direction === "asc"
          ? a[key].toString().localeCompare(b[key].toString())
          : b[key].toString().localeCompare(a[key].toString());
      }
    });

    setTableData(sortedData);
    setSortConfig({ key, direction });
  };

  const renderTable = (data) => (
    <div className="scrollable-table w-[90vw] rounded-md">
      <table className="info w-full">
        <thead className="bg-[#252525] pb-4">
          <tr>
            {[
              { key: "no", label: "No.", sortable: true, type: "number" },
              {
                key: "vendor",
                label: "VENDOR",
                sortable: true,
                type: "string",
              },
              {
                key: "activeInvDate",
                label: "ACTIVE INV. DATE",
                sortable: true,
                type: "string",
              },
              {
                key: "activeInvNo",
                label: "ACTIVE INV. NO.",
                sortable: true,
                type: "string",
              },
              {
                key: "payableAmount",
                label: "PAYABLE AMOUNT",
                sortable: true,
                type: "number",
              },
              {
                key: "gstAmount",
                label: "GST AMOUNT",
                sortable: true,
                type: "number",
              },
              {
                key: "totalAmount",
                label: "TOTAL AMOUNT",
                sortable: true,
                type: "number",
              },
              { key: "email", label: "EMAIL", sortable: true, type: "string" },
              {
                key: "gstAvailable",
                label: "GST AVAILABLE",
                sortable: true,
                type: "string",
              },
              { key: "gstin", label: "GSTIN", sortable: true, type: "string" },
              { key: "pan", label: "PAN", sortable: true, type: "string" },
              { key: "state", label: "STATE", sortable: true, type: "string" },
              { key: "cin", label: "CIN", sortable: true, type: "string" },
              { key: "sac", label: "SAC", sortable: true, type: "string" },
              {
                key: "bankName",
                label: "BANK NAME",
                sortable: true,
                type: "string",
              },
              {
                key: "branch",
                label: "BRANCH",
                sortable: true,
                type: "string",
              },
              {
                key: "accountType",
                label: "ACCOUNT TYPE",
                sortable: true,
                type: "string",
              },
              {
                key: "accountNo",
                label: "ACCOUNT NO.",
                sortable: true,
                type: "string",
              },
              { key: "ifsc", label: "IFSC", sortable: true, type: "string" },
              {
                key: "downloadActiveInv",
                label: "DOWNLOAD ACTIVE INV.",
                sortable: false,
              },
              { key: "cancelCheque", label: "CANCEL CHEQ.", sortable: false },
              { key: "msme", label: "MSME", sortable: false },
              { key: "panImage", label: "PAN IMAGE", sortable: false },
              { key: "gstImage", label: "GST IMAGE", sortable: false },
              { key: "editUpdate", label: "EDIT & UPDATE", sortable: false },
              { key: "markPaid", label: "MARK PAID", sortable: false },
            ].map(({ key, label, sortable, type }) => (
              <th
                key={key}
                className="mis-head min-w-[60px]"
                style={{ minWidth: "60px" }}
              >
                <div className="flex items-center justify-between">
                  <span>{label}</span>
                  {sortable && (
                    <button
                      onClick={() => sortTable(key, type)}
                      className="ml-2 flex flex-col"
                    >
                      <span
                        className={`text-xs ${
                          sortConfig.key === key &&
                          sortConfig.direction === "asc"
                            ? "text-white"
                            : "text-gray-400"
                        }`}
                      >
                        ↑
                      </span>
                      <span
                        className={`text-xs ${
                          sortConfig.key === key &&
                          sortConfig.direction === "desc"
                            ? "text-white"
                            : "text-gray-400"
                        }`}
                      >
                        ↓
                      </span>
                    </button>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, index) => (
            <React.Fragment key={index}>
              <tr key={item.no}>
                {/* Render all data columns */}
                {Object.keys(item).map((key) => (
                  <td
                    key={key}
                    className="mis-data"
                    style={{ minWidth: "60px" }}
                  >
                    {key === "panImage" ||
                    key === "gstImage" ||
                    key === "msme" ||
                    key === "cancelCheque" ? (
                      <div className="flex justify-center gap-2">
                        <FaEye className="inline cursor-pointer" />
                        <FaDownload className="inline cursor-pointer" />
                      </div>
                    ) : key === "downloadActiveInv" ? (
                      <div className="flex justify-center">
                        <FaDownload className="cursor-pointer" />
                      </div>
                    ) : (
                      item[key]
                    )}
                  </td>
                ))}
                {/* Always render the action buttons */}
                <td className="mis-data" style={{ minWidth: "60px" }}>
                  <div className="flex justify-center">
                    <FaEdit className="cursor-pointer hover:text-blue-500" />
                  </div>
                </td>
                <td className="mis-data" style={{ minWidth: "50px" }}>
                  <div className="flex justify-center">
                    <FaCheck className="cursor-pointer hover:text-green-500" />
                  </div>
                </td>
              </tr>
              <tr className="gap-row">
                <td colSpan="26"></td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <>
      <Header />
      <div className="flex ml-[5vw] mr-[5vw] mt-[5vh]">
        <div className="flex flex-col">
          <div className="flex items-center bg-[#1E1E1E] w-[90vw] h-[10vh] rounded-md">
            <div className="text-[#FF6C2F] text-[28px] ml-[5vw] font-medium">
              MIS
            </div>
            <div className="bg-[#FFFFFF] w-[1px] h-[25px] ml-[2vw]"></div>
            <Link
              to="/active-invoice"
              className="text-white text-[24px] ml-[2vw] font-medium"
            >
              Active Invoices
            </Link>
            <Link
              to="/paid-invoice"
              className="text-[#5B5B5B] text-[24px] ml-[2vw] font-medium"
            >
              Paid Invoices
            </Link>
          </div>
          <br />
          <div className="flex justify-between bg-[#1E1E1E] w-[90vw] text-[0.8rem] text-white px-4 py-3 rounded-md">
            <div className=" flex w-[18%] ml-[0.5%] flex justify-between">
              <button
                className="flex justify-center items-center bg-[#161616] h-[7vh] rounded-md w-[7vw] border-[1px] border-[#FF6C2F]"
                onClick={handleDownloadExcel}
              >
                Excel{" "}
                <IoMdDownload
                  style={{
                    color: "white",
                    fontSize: "1.4rem",
                    padding: "4px",
                  }}
                />
              </button>
              <button
                className="flex justify-center items-center bg-[#161616] h-[7vh] rounded-md w-[7vw] border-[1px] border-[#FF6C2F]"
                onClick={handleDownloadCSV}
              >
                CSV{" "}
                <IoMdDownload
                  style={{
                    color: "white",
                    fontSize: "1.4rem",
                    padding: "4px",
                  }}
                />
              </button>
            </div>
            <div className="w-[48%] relative">
              <input
                type="search"
                name="searchBar"
                id=""
                className="bg-[#252525] text-white rounded-md outline-none border-[1px] border-[#FF6C2F] p-[10px] pl-[50px] w-full h-[7vh]"
                placeholder="Search.."
                //   value={searchQuery}
                //   onChange={handleSearchChange}
              />
              <div className="absolute top-[50%] left-[15px] transform -translate-y-[50%]">
                <IoSearchOutline className="text-white" />
              </div>
            </div>
          </div>
          <br />
          <div className="mt-[2vh] mb-[2vh]">{renderTable(tableData)}</div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}
