import React, { useState} from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function UploadRR() {
  const [selectedFiles, setSelectedFiles] = useState({
    vendorSheet: "",
    dataSheet: "",
    summarySheet: "",
  });

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];

    if (file && file.name.endsWith(".csv")) {
      setSelectedFiles((prev) => ({ ...prev, [type]: file.name }));
    } else {
      toast.error("Please upload a .CSV file only!");
      event.target.value = ""; // Reset file input if not CSV
    }
  };

  return (
    <>
      <Header />
      <div className="flex ml-[5vw] mr-[5vw] mt-[5vh] mb-8">
        <div className="flex flex-col">
          <div className="flex items-center bg-[#1E1E1E] w-[90vw] h-[10vh] rounded-md">
            <div className="text-[#FF6C2F] text-[28px] ml-[5vw] font-medium">
              <Link to="/active-invoice">MIS</Link>
            </div>
            <div className="bg-[#FFFFFF] w-[1px] h-[25px] ml-[2vw]"></div>
            <Link
              to="/agreements"
              className="text-[#5B5B5B] text-[24px] ml-[2vw] font-medium"
            >
              Agreements
            </Link>
            <Link
              to="/new-users"
              className="text-[#5B5B5B] text-[24px] ml-[2vw] font-medium"
            >
              New Users
            </Link>
            <Link
              to="/upload-royalty-report"
              className="text-white text-[24px] ml-[2vw] font-medium"
            >
              Upload RR
            </Link>
          </div>
          <br />
          <div
            className="flex flex-col justify-between bg-[#1E1E1E] w-[auto] py-4 px-12 rounded-md text-white"
            style={{ fontWeight: "normal" }}
          >
            <h2>Instructions:</h2>
            <div className="bg-[#252525] px-6 py-4 font-normal text-[14px] mt-2">
              <ul className="list-disc list-inside text-[#FEC961]">
                <li>Please replace � symbol with ' throughout the files</li>
                <li>Please replace ’ symbol with ' throughout the files</li>
                <li>
                  Please select all other empty columns other than data columns
                  and delete throughout the files
                </li>
                <li>Upload .CSV File only</li>
              </ul>
            </div>
          </div>
          <br />
          <div className="flex flex-col gap-4">
            {/* Vendor Sheet Upload */}
            <div className="flex flex-col justify-between bg-[#1E1E1E] py-4 px-24 rounded-md text-white">
              <div className="flex flex-col px-6 py-4 font-normal text-[14px] text-[#FF6C2F] mt-2">
                Select Vendor Name - Email ID Sheet:
                <div className="flex items-center gap-2 mt-1">
                  <label
                    htmlFor="vendorSheet"
                    className="bg-[#131313] w-28 text-[#c5c5c5] text-[0.9rem] p-3 rounded-md hover:text-white hover:bg-[#FF6C2F] cursor-pointer "
                  >
                    Choose File
                  </label>
                  <span className="text-white text-[0.9rem] opacity-50">
                    {selectedFiles.vendorSheet || "No file chosen"}
                  </span>
                </div>
                <input
                  type="file"
                  id="vendorSheet"
                  hidden
                  accept=".csv"
                  onChange={(e) => handleFileChange(e, "vendorSheet")}
                />
              </div>
            </div>

            {/* Data Sheet Upload */}
            <div className="flex flex-col justify-between bg-[#1E1E1E] py-2 px-24 rounded-md text-white">
              <div className="flex flex-col px-6 py-4 font-normal text-[14px] text-[#FF6C2F] mt-2">
                Select Data Sheet:
                <div className="flex items-center gap-2 mt-1">
                  <label
                    htmlFor="dataSheet"
                    className="bg-[#131313] w-28 text-[#c5c5c5] text-[0.9rem] p-3 rounded-md hover:text-white hover:bg-[#FF6C2F] cursor-pointer"
                  >
                    Choose File
                  </label>
                  <span className="text-white text-[0.9rem] opacity-50">
                    {selectedFiles.dataSheet || "No file chosen"}
                  </span>
                </div>
                <input
                  type="file"
                  id="dataSheet"
                  hidden
                  accept=".csv"
                  onChange={(e) => handleFileChange(e, "dataSheet")}
                />
              </div>
            </div>

            {/* Summary Sheet Upload */}
            <div className="flex flex-col justify-between bg-[#1E1E1E] py-4 px-24 rounded-md text-white">
              <div className="flex flex-col px-6 py-4 font-normal text-[14px] text-[#FF6C2F] mt-2">
                Select Summary Sheet:
                <div className="flex items-center gap-2 mt-1">
                  <label
                    htmlFor="summarySheet"
                    className="bg-[#131313] w-28 text-[#c5c5c5] text-[0.9rem] p-3 rounded-md hover:text-white hover:bg-[#FF6C2F] cursor-pointer"
                  >
                    Choose File
                  </label>
                  <span className="text-white text-[0.9rem] opacity-50">
                    {selectedFiles.summarySheet || "No file chosen"}
                  </span>
                </div>
                <input
                  type="file"
                  id="summarySheet"
                  hidden
                  accept=".csv"
                  onChange={(e) => handleFileChange(e, "summarySheet")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
